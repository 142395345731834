


























import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ConfigSelector from './ConfigSelector.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

import { DataSourceTemplate } from '@/models/data/models';

@Component({
  components: {
    ConfigSelector,
  },
  data() {
    return {
      DataSourceTemplate,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class DataSourceTemplateForm extends Vue {
  @Prop({ required: true }) id: string;

  plugin_id = '';

  onLoaded(template: DataSourceTemplate) {
    this.plugin_id = template.plugin_id;
  }

  beforeSaveHook(template: DataSourceTemplate) {
    if (this.id === '0') {
      template.application = this.$store.getters['global/application'].id;
      template.plugin_id = this.$routerHandler.query('')[
        'data_source_plugin_id'
      ];
    }
  }
}
